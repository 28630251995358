// ActionCard.js
import React from 'react';
import './ActionCard.css'; // Import the CSS file
import CTOPersonIMg from '../images/CTOPerson.png'
import { Link } from 'react-router-dom';
const ActionCard = () => {
    return (

        <div className="action-card-container">
            <div className='CTOCont'>
                    <img className='CTOImgCont' src={CTOPersonIMg}/>
            </div>
            <div className="action-card">
            <p className="title">GOT A CONCEPT<br></br>BUT NO TECHNICAL PLANS?</p>
                <p className="subtitle">Get CTO Consultation<br></br>for a concrete technical roadmap.</p>
                {/* <p className="cta">Get your free consultation now and start your journey!</p> */}
                <Link to='/consultation-details'>
                <button className='SessionBtn'>
                    Schedule a Session
                </button>
                </Link>
                
            </div>
        </div>
    );
};

export default ActionCard;
