// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC5NvkgbZ7NedNGQ5ckG3pm_z5dJtocAjk",
  authDomain: "fiddle-webapp.firebaseapp.com",
  projectId: "fiddle-webapp",
  storageBucket: "fiddle-webapp.appspot.com",
  messagingSenderId: "281032446596",
  appId: "1:281032446596:web:8da965aa47ae86e78fa8d1",
  measurementId: "G-8YFSYHY500"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
