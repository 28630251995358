import React from 'react';
import './Footer.css';
import logo from '../images/idealab.png';

function Footer() {
  return (
    <footer className="footer">
    {/* <hr></hr> */}
      <div className="footer-container">
        <div className="footer-section">
          <div className="logo-container">
            <img src={logo} alt="Logo" className="logo" />
          </div>
          <p className="company-brief">From ideation , development, to launch and upgradation, we hold your hands to build your next Engineered Products </p>
        </div>
        <div className="footer-section">
          <h3>Contact</h3>
          <p> Mail: hello@rowx.in</p>
          <p> Call: 9608810232</p>
        </div>
        {/* <div className="footer-section">
          <h3>Connect</h3>
          <div className="newsletter">
            <input type="text" placeholder="Enter your email" />
            <button>Subscribe</button>
          </div>
          <div className="social-icons">
            <a href="#"><i className="fab fa-facebook-square"></i></a>
            <a href="#"><i className="fab fa-twitter-square"></i></a>
            <a href="#"><i className="fab fa-instagram-square"></i></a>
          </div>
        </div> */}
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 RowX. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
