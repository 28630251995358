// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Components/Home';
import Navbar from './Components/Navbar';
// import FloatingMenu from './Components/FloatingMenu';
import ConsultationForm from './Components/ConsultationForm';
import './App.css';
import SessionDetailPage from './Components/SessionDetailPage';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/consultation-details" element={<SessionDetailPage />}/>
          <Route path="/consultation-form" element={<ConsultationForm />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
