import React, { useState, useEffect } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import './FloatingMenu.css'; // Import the CSS file for FloatingMenu
import { RiMenu4Fill } from "react-icons/ri";
import { IoIosCloseCircleOutline } from "react-icons/io";

function FloatingMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!isSmallScreen) {
    return null; // Don't render the component if not on a small screen
  }

  return (
    <div className="floating-menu">
      <button className="floating-menu-button" onClick={toggleMenu}>
        {isOpen ? <IoIosCloseCircleOutline/> : <RiMenu4Fill/>}
      </button>
      <div className={`floating-menu-content ${isOpen ? 'open' : ''}`}>
        <ul className="floating-menu-links">
          <li><a href="/" className="menu-link">Home</a></li>
          <li><a href="/#service" className="menu-link">What we do</a></li>
          <li><a href="/#HowWeDo" className="menu-link">How we do</a></li>
          <li><a href="/#choose" className="menu-link">Why Choose Us!</a></li>
          {/* <li>
            <a 
              href="https://wa.me/your_number" 
              className="whatsapp-button" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <FaWhatsapp size={30} />
            </a>
          </li> */}
        </ul>
      </div>
    </div>
  );
}

export default FloatingMenu;
