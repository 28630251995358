import React, { useRef } from 'react';
import './Home.css';
import { motion, useInView } from 'framer-motion';
import LandingImg2 from '../images/CTO.png';
import Person from "../images/Person.png";
import ClientCrousel from './ClientCrousel';
import Footer from './Footer';
import TechCards from './TechCards';
import HowWeDo from './HowWeDo';
import ActionCard from './ActionCard';
import FloatingMenu from './FloatingMenu';
import { Link } from 'react-router-dom';

const containerVariants = {
  hidden: { opacity: 0, x: '-100vw' },
  visible: {
    opacity: 1,
    x: 0,
    transition: { type: 'spring', delay: 0.5 }
  }
};

const fadeIn = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 2 }
  }
};

function Home() {
  // const landingContainerRef = useRef(null);
  // const landingInView = useInView(landingContainerRef, { once: true });

  const clientRef = useRef(null);
  const clientInView = useInView(clientRef, { once: true });

  const belowLandingRef = useRef(null);
  const belowLandingInView = useInView(belowLandingRef, { once: true });

  const whyContRef = useRef(null);
  const whyContInView = useInView(whyContRef, { once: true });

  const whyChooseUsRef = useRef(null);
  const whyChooseUsInView = useInView(whyChooseUsRef, { once: true });

  const reasonsContRef = useRef(null);
  const reasonsContInView = useInView(reasonsContRef, { once: true });

  const newsletterRef = useRef(null);
  const newsletterInView = useInView(newsletterRef, { once: true });

  const footerDivRef = useRef(null);
  const footerDivInView = useInView(footerDivRef, { once: true });

  return (
    <div className='HomeMainCont'>
      <motion.div 
        className="LandingContainer"
        id='homecont'
        variants={containerVariants}
        initial="hidden"
        animate='visible'
        // animate={landingInView ? "visible" : "hidden"}
      >
        <div className='LandingText'>
          <h2>YOUR BUSINESS NEED TECH?</h2>
          <br></br>
          <h3>LEAVE IT ON US</h3>
          <div className='Underline'></div>
          <h1 className='subheading'>
            WE WILL HANDLE YOUR TECH, WHILE YOU FOCUS ON YOUR BUSINESS.
          </h1>
          <Link to='/consultation-details'>
          <button className='Talk'>
            Let's Talk
          </button>
          </Link>
        </div>
        <motion.div 
          className='LandingImg'
          variants={fadeIn}
        >
          <img className='LandingImage' src={LandingImg2} alt='landing img'/>  
        </motion.div>
      </motion.div>

      <motion.div 
        ref={clientRef}
        className='client'
        variants={fadeIn}
        initial="hidden"
        animate={clientInView ? "visible" : "hidden"}
      >
        <ClientCrousel />
      </motion.div>
      <motion.div 
        ref={belowLandingRef}
        className='BelowLandingCont'
        variants={fadeIn}
        initial="hidden"
        animate={belowLandingInView ? "visible" : "hidden"}
      >
        <h1>Achieve Your Business Goal with Our <span>CTO service</span></h1>
        <br></br>
        <div className='line'></div>
        <div className='Pitching'>
          <p>Startups need a skilled CTO to develop technology roadmaps, ideate, innovate, and build groundbreaking products. Our services offer expert CTO capabilities without the overhead of a full-time hire.</p>
        </div>
      </motion.div>

      <motion.div 
        ref={whyContRef}
        id='service' 
        className='WhyCont'
        variants={fadeIn}
        initial="hidden"
        animate="visible" 
      >
        <h1>What we do?</h1>
        <p>We iteratively build your next revolutionary software enginnered products.</p>
        <div className='TechCards'>
          <TechCards />
        </div>
      </motion.div>

      <motion.div 
        ref={whyContRef}
        id='HowWeDo' 
        className='WhyCont'
        variants={fadeIn}
        initial="hidden"
        animate={whyContInView ? "visible" : "hidden"}
      >
        <h1>How we do?</h1>
        <p>Building, Testing, Learning: The Iterative Software Development Process</p>
        <div className='TechCards'>
          <HowWeDo />
        </div>
      </motion.div>


      <motion.div 
        ref={whyChooseUsRef}
        id='choose' 
        className='WhyChooseUsCont'
        variants={fadeIn}
        initial="hidden"
        animate={whyChooseUsInView ? "visible" : "hidden"}
      >
        <h1>Why RowX's CTO as a Service? </h1>
        <br></br>
        <div className='line'></div>
        <br></br>
        <p>For fast-paced startups, managing technology can be a distraction. Our CTO as a Service collaborates with you to create and implement a technical roadmap aligned with growth goals, providing technical expertise without the overhead of a full-time hire.</p>
        <br></br>
      </motion.div>

      <motion.div 
        ref={reasonsContRef}
        className='ReasonsCont'
        variants={fadeIn}
        initial="hidden"
        animate={reasonsContInView ? "visible" : "hidden"}
      >
        <div className='ReasonsImg'>
          <img className='PersonImg' src={Person} alt='cto'/>
        </div>
        <div className='ListOfReasonsCont'>
          <div className='Reason1'>
            <h3>✅Clear Communication</h3>
            <p>Efficient communication with and within teams means no loose ends for you, always on the same page with developers.</p>
          </div>
          <div className='Reason1'>
            <h3>✅Full Control</h3>
            <p>From conceptualization to delivery and maintenance, you stay in the driver’s seat at all times.</p>
          </div>
          <div className='Reason1'>
            <h3>✅Extremely Agile</h3>
            <p>Your business needs to keep up with continuously evolving market trends and customer expectations. We are extraordinarily agile so that you can pivot strategies at will.</p>
          </div>
          <div className='Reason1'>
            <h3>✅End to End Services</h3>
            <p>Be it custom software, cloud solution, blockchain mobile app, web app, or any other service, you can leave it all upon us to design, develop, test, maintain, and provide support for the solution.</p>
          </div>
          <div className='Reason1'>
            <h3>✅Transparent Conduct</h3>
            <p>So accessible and open that you’ll feel we are an extended part of your team. Periodic reports, access to tools, and the nurtured transparent culture keeps you abreast of all developments.</p>
          </div>
        </div>
      </motion.div>

      <motion.div 
        ref={newsletterRef}
        variants={fadeIn}
        initial="hidden"
        animate={newsletterInView ? "visible" : "hidden"}
      >
        {/* <div>
          <h3>Get Your CTO & create the best software engineered product for your business</h3>
        </div>
        <div>
        <a href="mailto:hello@rowx.in">
          <button className="contact-button">Mail Us Now</button>
        </a>
        </div> */}

        <ActionCard/>
      </motion.div>

      <motion.div 
        ref={footerDivRef}
        className='FooterDiv'
        variants={fadeIn}
        initial="hidden"
        animate={footerDivInView ? "visible" : "hidden"}
      >
        <Footer />
      </motion.div>
      <FloatingMenu/>

    </div>
  );
}

export default Home;
