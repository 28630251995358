import React from 'react';
import './SessionDetailPage.css';
import CoverImg from '../images/CoverImg.png';
import ConsultationForm from './ConsultationForm';
import Logo from '../images/ROW.png'
const SessionDetailPage = () => {
    return (
        <div className="detail-page">
            <div className="cover-section">
            <div className='CoverImgCont'>
            <img src={CoverImg} alt="CTO Consultation Cover" className="cover-image" />

            </div>
                {/* <img src={Logo} alt="Profile" className="profile-image" /> */}
            </div>
            <div className="content-section">
                <h1 className="title">1 on 1 CTO Consultation</h1>
                <div className='content-subsection'>
                <div className="description">
                    <h2>What You Will Get</h2>
                    <ul>
                        <li><strong>Personalized Technical Roadmap:</strong> Our expert CTOs will work with you to create a tailored technical roadmap for your project.</li>
                        <li><strong>In-depth Technical Insights:</strong> Gain a deeper understanding of the technical aspects of your project, from architecture to implementation.</li>
                        <li><strong>Strategic Advice:</strong> Receive strategic advice on how to scale your project, manage technical debt, and optimize performance.</li>
                        <li><strong>Q&A Session:</strong> Get answers to all your technical questions in a one-on-one setting.</li>
                        <li><strong>Follow-up Support:</strong> Enjoy follow-up support to ensure you're on the right track even after the consultation.</li>
                    </ul>
                </div>
                <div className="form-section">
                    {/* <h2>Schedule a Meeting</h2> */}
                    <ConsultationForm />
                </div>
                </div>
            </div>
        </div>
    );
};

export default SessionDetailPage;
