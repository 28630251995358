import React, { useState } from 'react';
import './HowWeDo.css';
import image1 from '../images/image1.png';
import image2 from '../images/image2.png';
import image3 from '../images/image3.png';
import image4 from '../images/image4.png';
import image5 from '../images/image5.png';
import image6 from '../images/image6.png';
import image7 from '../images/image7.png';




const headings = [
  "Conceptualize Your Vision with Us",
  "Crafting a Technical Roadmap Tailored to Your Ideas",
  " Designing a Stunning, User-Centric Application",
  " Codde and Implement Essential Features",

  "Testing & Deployment",
  "Gather User Feedback & Refine Continuously",
  "Innovate,Enhance & Grow with each iteration"
];

const details = [
  "We work closely with you to understand your vision and requirements.We brainstorm and refine your ideas.Our team ensures your goals are clearly defined and aligned with your aspirations, creating a roadmap for turning concepts into reality., setting a strong foundation for success",
  "Based on your concept, we create a comprehensive technical roadmap, outlining each development step.his strategic plan ensures clarity and efficiency, guiding us from concept to completion. ",

"Our talented designers craft visually appealing, user-friendly interfaces that enhance the user experience. We focus on intuitive design principles, ensuring your application is both aesthetically pleasing and easy to navigate, creating a strong visual identity that resonates with your audience.",

  "Our developers transform your ideas into functional features. With a focus on quality and user experience, we code and integrate each element, ensuring robustness and efficiency. Leveraging the latest technologies, we create a product that meets your precise requirements.",

  "Before launch, we conduct thorough testing to ensure a flawless user experience. Our rigorous quality assurance process resolves any issues, leading to a smooth deployment. We handle everything, ensuring your product is ready for the market and performs perfectly.",


  "Your User has a lot to say on your product. We continuously evolve your product based on real-world insights and user needs.This ongoing feedback loop allows us to make necessary refinements, enhancing performance and user satisfaction",

  "We embrace innovation and adaptability, pivoting to meet your goals. Each iteration brings enhancements and new features, ensuring your product stays relevant and competitive. Our iterative process drives ongoing growth and improvement, keeping your product at the forefront of the market."
];

const images = [
    image1,
    image2,
    image7,
    image3,
    image4,
    image5,
    image6,
    
  ];
  


const HowWeDo = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className='HowWeDoContainer'>
      <div className='Content'>
        <div className='LeftSide'>
          {headings.map((heading, index) => (
            <div
              key={index}
              className={`Heading ${index === activeIndex ? 'active' : ''}`}
              onClick={() => setActiveIndex(index)}
            >
              <h2>{heading}</h2>
            </div>
          ))}
        </div>
        <div className='RightSide'>
          <div className='DetailBox'>
            <div className='DetailboxImg'>
            <img src={images[activeIndex]} alt={headings[activeIndex]} />
            </div>
            <p>{details[activeIndex]}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowWeDo;
