import React, { useState, useEffect } from 'react';
import './Navbar.css'; // Import the CSS file
import logo from '../images/idealab.png'; // Import your logo image
import { FaWhatsapp } from 'react-icons/fa';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('');

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const predefinedMessage = encodeURIComponent("Hi, what are you offering?");
  const whatsappURL = `https://wa.me/9608810232?text=${predefinedMessage}`;

  useEffect(() => {
    // Set the active link based on the current URL
    const currentPath = window.location.pathname + window.location.hash;
    setActiveLink(currentPath);
  }, []);

  return (
    <div className='NavCont'>
      <nav className="navbar">
        <div className="navbar-left">
          <img src={logo} alt="Logo" className="navbar-logo" />
        </div>
        <div>
        <ul className={`navbar-nav ${isOpen ? 'open' : ''}`}>
          <li className="nav-item">
            <a
              href="/#homecont"
              className={`nav-link ${activeLink === '/' ? 'active' : ''}`}
              onClick={() => setActiveLink('/')}
            >
              Home
            </a>
          </li>
          <li className="nav-item">
            <a
              href="/#service"
              className={`nav-link ${activeLink === '/#service' ? 'active' : ''}`}
              onClick={() => setActiveLink('/#service')}
            >
              What we do
            </a>
          </li>
          <li className="nav-item">
            <a
              href="/#HowWeDo"
              className={`nav-link ${activeLink === '/#HowWeDo' ? 'active' : ''}`}
              onClick={() => setActiveLink('/#HowWeDo')}
            >
              How we do
            </a>
          </li>
          <li className="nav-item">
            <a
              href="/#choose"
              className={`nav-link ${activeLink === '/#choose' ? 'active' : ''}`}
              onClick={() => setActiveLink('/#choose')}
            >
              Why Choose Us!
            </a>
          </li>
        </ul>
        </div>
        <div className="navbar-right">
          <a
            href={whatsappURL}
            className="whatsapp-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaWhatsapp size={30} />
          </a>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
