// src/TechCards.js
import React from 'react';
import './TechCards.css';
import codingImg from '../images/coding.png';

const TechCards = () => {
  return (
    <div className="card-container">
      <div className="card">
        <h2>Tech Development</h2>
        <div className='CardWrappper'>
          <div className='techCard'>
            <h1>Web Application </h1>
            <p>Development</p>

          </div>
          <div className='techCard'>
            <h1>Mobile Application</h1>
            <p>Development</p>
          </div>
          <div className='techCard'>
            <h1>Blockchain</h1>
            <p>Development</p>
          </div>
          <div className='techCard'>
            <h1>AI</h1>
            <p>Development</p>

          </div>
        </div>
        {/* <div className='CodingImg'>
          <img src={codingImg} alt="Coding"/>
        </div> */}
      </div>

      <div className="card">
        <h2>Upscalling Tech</h2>
        <div>
          <div className='techCard'>
          <h1>Migrating</h1>
            <p> To Cloud Servers</p>
          </div>
          <div className='techCard'>
            <h1>Upgrading</h1>
            <p>Your System Design</p>
          </div>
          <div className='techCard'>
          <h1>Fixing</h1>
            <p>Your Meesed Up Codebase</p>
          </div>
          <div className='techCard'>
            <h1>Refactoring </h1>
            <p>Your Codebase</p>
          </div>
        </div>
        {/* <div className='CodingImg'>
          <img src={codingImg} alt="Coding"/>
        </div> */}
      </div>

    </div>
  );
};

export default TechCards;
