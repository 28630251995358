import React from 'react';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ClientCrousel.css';


import Client1 from '../images/Client5.png';
import Client2 from '../images/Client6.png';
import Client3 from '../images/Client7.png';
import Client4 from '../images/Client4.png';
import Client5 from '../images/Client1.png';
import Client6 from '../images/Client2.png';
import Client7 from '../images/Client3.png';
// import Client8 from '../images/Client.png';
// import Client9 from '../images/Client9.png';
// import Client10 from '../images/Client10.png';
// import Client11 from '../images/Client11.png';
// import Client12 from '../images/Client12.png';







function ClientsSection() {
  const settings = {
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 1000,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className="clients-section">
      <Slider {...settings}>
        <div className="client-logo">
          <img src={Client1} alt="Client 1" />
        </div>
        <div className="client-logo">
          <img src={Client2} alt="Client 2" />
        </div>
        <div className="client-logo">
          <img src={Client3} alt="Client 3" />
        </div>
        <div className="client-logo">
          <img src={Client4} alt="Client 1" />
        </div>
        <div className="client-logo">
          <img src={Client5} alt="Client 2" />
        </div>
        <div className="client-logo">
          <img src={Client6} alt="Client 3" />
        </div>
        <div className="client-logo">
          <img src={Client7} alt="Client 1" />
        </div>
        {/* <div className="client-logo">
          <img src={Client8} alt="Client 2" />
        </div>
        <div className="client-logo">
          <img src={Client9} alt="Client 3" />
        </div>
        <div className="client-logo">
          <img src={Client10} alt="Client 1" />
        </div>
        <div className="client-logo">
          <img src={Client11} alt="Client 1" />
        </div>
        <div className="client-logo">
          <img src={Client12} alt="Client 1" />
        </div> */}
       
        {/* Add more client logos */}
      </Slider>
    </div>
  );
}

export default ClientsSection;
