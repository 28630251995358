import React from 'react';
import Lottie from 'lottie-react';
import './ConsultationModal.css'; // Add your ConsultationModal CSS styles
import tickAnimation from '../Assets/tick-animation'; // Path to your Lottie JSON file

const ConsultationModal = ({ isOpen, onClose, data }) => {
    if (!isOpen) return null;

    return (
        <div className="ConsultationModal-overlay">
            <div className="ConsultationModal-content">
                <Lottie 
                    animationData={tickAnimation} 
                    loop={false} 
                    className='Lotte'
                />
                <h3>Consultation Booked</h3>
                <p>Hi {data.name}!</p>
                <p>Your Consultation with RowX booked on {data.selectedDate.toDateString()} at the time slot {data.timeSlot}</p>
                <button onClick={onClose}>OK</button>
            </div>
        </div>
    );
};

export default ConsultationModal;
